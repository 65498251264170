@import 'src/assets/scss/base';

.app {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100%;
  //background-image: url('../img/hops-pattern.svg');
  /*background-size: 110%;
  background-position: center;*/
}

.app__main-logo {
  height: 10vmin;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    height: 15vmin;
  }
}

//@media (prefers-reduced-motion: no-preference) {
//  .app-logo {
//    animation: App-logo-spin infinite 20s linear;
//  }
//}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  color: white;
  font-size: 28px;
  line-height: 32px;
  margin: 10px;

  @include media-breakpoint-up(lg) {
    margin: 40px auto;
  }
}

.app__header {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 15px 0;

  @include media-breakpoint-up(md) {
    margin: 20px 0;
  }

  @include media-breakpoint-up(lg) {
    margin: initial;
    min-height: 30vh;
  }
}

.app__main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  min-height: 100%;
}

.app__mono-logo {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100vh;
  opacity: 0.044;
}

.app__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 5;
  color: white;
  font-size: 12px;
  margin: 20px 10px 0;
  border-top: 1px solid #222222;
  padding: 5px;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    margin: 20px 40px 0;
  }

  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
    margin: 20px 80px 0;
    padding: 10px;
  }

  &--credit {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      margin-right: 25px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 50px;
    }
  }
}


.language-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;

  &__text {
    color: white;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  &__flag {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 2px 1px 0 4px rgba(0,0,0,0.8);
    margin: 20px 40px;
    transition: all ease-in-out 0.26s;

    &:hover {
      cursor: pointer;
      width: 70px;
      height: 70px;
      margin: 15px 35px;
    }

    &--de {
      background-image: url('../img/de.svg');
    }

    &--en {
      background-image: url('../img/en.svg');
    }
  }
}

.short-term-membership-info {
  margin: 50px 0 60px;
  padding: 20px 20px 25px;
  border: 1px solid grey;
  border-radius: 12px;
  background-color: rgba(100,100,100,0.3);

  h3 {
    margin-top: 0;
    color: $primary;
  }

  label.form__item--checkbox {
    color: $primary;
    max-width: none;
    display: flex;
    align-items: center;
    gap: 25px;
    margin: 0;

    .checkbox-wrapper {
      display: flex;
      width: 24px;
      height: 24px;

      input {
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        min-height: 0;
      }
    }
  }
}
