.loader {
  border: 7px solid #141612;
  border-top: 7px solid #c5a135;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.6s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
