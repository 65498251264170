@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600&display=swap');

* {
  box-sizing: border-box;
}

@mixin font_nunito() {
  font-family: "Nunito", 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

@mixin font_hind() {
  font-family: "Hind", 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

$primary: #c5a135; // 192,161,74

@mixin bg_image($size: auto) {
  background-position: center;
  background-size: $size;
  background-repeat: no-repeat;
}

//@mixin media-breakpoint-up($screen-width) {
//  // X-Small devices (portrait phones, less than 576px)
//  // No media query for `xs` since this is the default in Bootstrap
//
//  // Small devices (landscape phones, 576px and up)
//  @media (min-width: 576px) { ... }
//
//  // Medium devices (tablets, 768px and up)
//  @media (min-width: 768px) { ... }
//
//  // Large devices (desktops, 992px and up)
//  @media (min-width: 992px) { ... }
//
//  // X-Large devices (large desktops, 1200px and up)
//  @media (min-width: 1200px) { ... }
//
//  // XX-Large devices (larger desktops, 1400px and up)
//  @media (min-width: 1400px) { ... }
//}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($screen-width) {
  @if ($screen-width == md) {
    @media (min-width: 768px) {
      @content;
    }
  } @else if ($screen-width == lg) {
    @media (min-width: 999px) {
      @content;
    }
  }  @else if ($screen-width == xl) {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @content;
  }
}
