@import './base';

form,
.form {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  height: 100%;
  color: white;
  margin: 0 10px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #222222;
  text-align: left;

  @include media-breakpoint-up(md) {
    margin: 30px;
    padding: 30px 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: 50px 30%;
    padding: 30px 40px;
    max-width: 780px;
  }
}

.form__item {
  display: block;
  position: relative;
  //width: fit-content;
  margin: 15px auto;
  width: 100%;
  max-width: 300px;

  label {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 5px;
  }

  input {
    display: block;
    position: relative;
    color: white;
    border-radius: 2px;
    font-size: 18px;
    padding: 6px 15px 5px;
    background: transparent;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    border: 1px solid rgba(255, 255, 255, 0.42);
    transition: all ease-in-out 0.18s;
    min-height: 44px;
    width: 100%;

    &:focus,
    &:focus-visible {
      background: rgba(255, 255, 255, 0.84);
      background: rgba(255, 255, 255, 0.32);
      background: rgba(0, 0, 0, 0.28);
      border: 1px solid rgba(255, 255, 255, 0.90);
      border: 1px solid rgb(192, 161, 74);
      color: white;
      outline: 1px solid #c0a14a;
      letter-spacing: 1px;
    }

    @include media-breakpoint-up(md) {
      font-size: 26px;
      padding: 8px 30px 7px;
    }

    &[type="date"] {
      min-height: 40px;
    }
  }

  &--checkbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: $primary;
    font-size: 22px;
    font-weight: 500;

    .checkbox-wrapper {
      display: flex;
      width: 24px;
      height: 24px;

      input {
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        min-height: 0;
      }
    }
  }
}

button {
  @include font_hind();

  text-transform: uppercase;
  font-size: 20px;
  padding: 8px 30px 7px;
  letter-spacing: 2px;
  border-radius: 2px;
  font-weight: 500;
  color: $primary;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(192, 161, 74);
  transition: background 0.2s ease;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
  }

  &.form-navigation__previous-button {
    color: $primary;
    background: rgba(0, 0, 0, 0.2);
  }

  &.form-navigation__next-button {
    opacity: 0.42;

    &--validated {
      opacity: 1;
      color: white;
      background: rgba(192, 161, 74, 0.8);
    }
  }
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form__progress-bar {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 70%;
  margin: 0 auto 30px;

  &--item {
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: rgba(192, 161, 74, 0.2);
    border: 1px solid rgb(192, 161, 74);
    font-weight: 600;

    &.active {
      background: rgba(192, 161, 74, 0.7);
    }
  }

  &--line {
    z-index: 1;
    display: block;
    position: absolute;
    border-bottom: 1px solid rgba(192, 161, 74, 0.2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
  }
}

.form__content {
  flex: 1;
  margin: 0 10px;
}

.form__review {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 25px auto;
  border: 1px solid $primary;
  padding: 20px 30px;

  button {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    padding: 5px 20px 4px;
  }
}

.form__review-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  .label {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .value {
    padding-left: 20px;
    color: $primary;
  }
}

.form__notification {
  display: block;
  position: relative;
  margin-bottom: 20px;
  border: 2px solid #5f1a00;
  padding: 4px 10px 5px 50px;
  color: white;

  &-icon {
    @include bg_image(contain);

    content: '';
    display: block;
    width: 24px;
    background-color: #5f1a00;
    background-image: url('../img/exclamation.svg');
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 5px solid #5f1a00;
    border-left: 3px solid #5f1a00;
  }
}
