@import './base';

.gender-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 20px;

  label {
    display: flex;
    align-items: center;
    width: fit-content;
    min-height: 44px;
    border: 1px solid rgba(255, 255, 255, 0.42);
    border-radius: 3px;
    padding: 5px 15px 5px 10px;
    color: white;
    font-size: 18px;
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, 0.4);

    &.selected {
      border-color: $primary;
      color: white;
    }

    input {
      margin-right: 15px;
    }
  }
}