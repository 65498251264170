@import './base';

.privacy-item {
  margin: 10px 0 20px;
  border: 1px solid $primary;

  .privacy-item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px 5px;
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }

  .privacy-item__header--title {
    display: flex;
    align-items: center;
  }

  .privacy-item__header--heading {
    flex: 1;
  }

  .privacy-item__header--checkbox {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 20px;

    &-circle {
      z-index: 1;
      display: block;
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 2px;
      left: 2px;
      border-radius: 100%;
      border: 1px solid #121212;
    }

    &-check {
      @include bg_image();

      z-index: 2;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity ease-in-out 0.5s;
      background-image: url(../img/check.svg);
      background-position: 20% 50%;
    }
  }

  .privacy-item__content {
    background: rgba(0,0,0,0.3);
    padding: 10px 20px;
    font-size: 16px;

    label {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.checked {
    .privacy-item__header {
      background: rgba(192,161,74, 0.94);
      color: white;
    }

    .privacy-item__header--checkbox {
      &-circle {
        border: 1px solid white;
        background-color: rgba(0,0,0,0.3);
      }

      &-check {
        opacity: 1;
      }
    }
  }
}
