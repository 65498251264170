@import './base';

.number-select {
  display: grid;
  //grid-template-rows: repeat(10, 1fr);
  //grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.number-select__number {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  background-color: rgba(0,0,0,0.4);
  color: #4b4b4b;
  min-height: 42px;

  &.available {
    color: $primary;

    &:hover {
      cursor: pointer;
    }
  }

  &.selected {
    background-color: $primary;
    color: black;
    font-weight: 900;
    font-size: 23px;
    letter-spacing: 1px;
  }
}

.number__unlock-all-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 0 25px;
  padding: 5px 10px;
  border: 1px solid grey;

  @include media-breakpoint-up(md) {
    gap: 25px;
    margin: 0 auto 30px;
    padding: 10px 20px;
    width: fit-content;
  }

  .number__unlock-all-checkbox {

  }

  .number__unlock-all-checkbox-description {
    .number__unlock-all-checkbox-description--small {
      font-size: 16px;
      font-style: italic;
    }
  }
}

.number__selected-number-info {
  color: $primary;
  text-align: right;

  &--large {
    margin-left: 15px;
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 1px;
    color: $primary;
  }

  &--small {
    margin: 10px 0 20px;
    color: white;
    font-size: 15px;
    text-align: right;
  }
}